jQuery(function($){
	'use strict';

  // $.validator.addMethod("checkboxGroup", function(value, elem, param) {
  //     if($(".checkbox-group:checkbox:checked").length > 0){
  //       return true;
  //   }else {
  //       return false;
  //   }
  // },"You must select at least one!");

  function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
  };


  $("form.contact-form").validate({
    submitHandler: function(form) {
      var $form = $(form);

      jQuery.ajax({
        url: "/content/includes/contact.asp",
        type: 'POST',
        data: $form.serialize(),
        success: function() {
          $form.parent().html("<h3>Thank you for your interest in FW Cook. One of our advisors will respond promptly.</h3>");
      if (typeof dataLayer === "object") {
        dataLayer.push({
          "event": "form-submission",
          "status": "submitted",
          "form_name": "contact"
        });
        }
        }
      });
    }
  })

  $("form.compensation-contact-form").validate({
    submitHandler: function(form) {
      var $form = $(form);

      jQuery.ajax({
        url: "/content/includes/compensation-contact.asp",
        type: 'POST',
        data: $form.serialize(),
        success: function() {
          $form.parent().html("<h3>Thank you for your interest in FW Cook. One of our advisors will respond promptly.</h3>");
      if (typeof dataLayer === "object") {
        dataLayer.push({
          "event": "form-submission",
          "status": "submitted",
          "form_name": "contact"
        });
        }
        }
      });
    }
  })

  $("form.speaker-form").validate({
    submitHandler: function(form) {
      var $form = $(form);

      jQuery.ajax({
        url: "/content/includes/speaker.asp",
        type: 'POST',
        data: $form.serialize(),
        success: function() {
          $form.parent().html("<h3>Thank you for your interest in FW Cook. One of our advisors will respond promptly.</h3>");
      if (typeof dataLayer === "object") {
        dataLayer.push({
          "event": "form-submission",
          "status": "submitted",
          "form_name": "speaker"
        });
        }
        }
      });
    }
  })

  $("form.share-form").validate({
    submitHandler: function(form) {
      var $form = $(form);

      jQuery.ajax({
        url: "/content/includes/share-job.asp",
        type: 'POST',
        data: $form.serialize(),
        success: function() {
          $form.parent().html("<h3>Thank you for your interest in FW Cook.</h3>");
      if (typeof dataLayer === "object") {
        dataLayer.push({
          "event": "form-submission",
          "status": "submitted",
          "form_name": "share"
        });
      }
        }
      });
    }
  })

  $("form.job-form").validate({
    submitHandler: function(form,event) {
      var $form = jQuery(form);
      $form.find("button").html('<div class="icon-spin animate-spin" style=""></div>Uploading');
      $form.ajaxSubmit({
        url: "/content/includes/resume.asp",
        success: function(){
          $form.parent().html("<h3>Thank you for your interest in FW Cook.</h3>");

          if (typeof dataLayer === "object") {
        dataLayer.push({
          "event": "form-submission",
          "status": "submitted",
          "form_name": "job"
        });
        }
        }
      });

  /*
      jQuery.ajax({
        url: "/content/includes/resume.asp",
        type: 'POST',
        data: new window.FormData($form[0]),
        processData: false,
        contentType: false,
        success: function() {
          //var thankyou = jQuery('.thank-you', $form).html();
          //ga('send', 'event', 'ResumeForm', 'Submitted', 'Apply Form');
          $form.parent().html("<h3>Thank you for your interest in FW Cook.</h3>");
        }
      });
  */
    }
  });


  // $( "#sub" ).rules( "add", {
  //   required: function (element) {
  //     console.log("test");
  //     var boxes = $('.checkbox-group');
  //     if (boxes.filter(':checked').length == 0) {
  //         return true;
  //     }
  //     return false;

  //   },
  //   minlength: 1,
  //   messages: {
  //     required: "Required input",
  //     minlength: jQuery.validator.format("Please, at least {0} characters are necessary")
  //   }
  // });

  $("form.subscribe-form").validate({
    rules: {
      sub: {
        required: function (element) {
          var boxes = $('.checkbox-group');
          if (boxes.filter(':checked').length == 0) {
            return true;
          }
          return false;
        },
        minlength: 1
      }
    },
    messages: {
      sub: "Please select at least one type of subscription."
    },
    submitHandler: function(form,event) {
      var $form = $(form);
      $form.find("button").addClass("icon-spin").prop("disabled", true);
      $.ajax({
        url: "/content/includes/new-subscribe.ashx",
        type: 'POST',
        data: $form.serialize(),
        success: function(msg) {
          $form.html(msg);

          if (typeof dataLayer === "object") {
            dataLayer.push({
              "event": "form-submission",
              "status": "submitted",
              "form_name": "subscribe"
            });
          }
        }
      });
    }
  });

  if ($("form.unsubscribe")) {
    var email = getUrlVars()["email"];
    var type = getUrlVars()["type"];

    if (email) {
      email = email.replace(/%40/i, "@");
      $("form.unsubscribe input.email").val(email);
    }

    if (type === "publications") {
      $("form.unsubscribe input#publications").parent().parent().hide();
      $("form.unsubscribe span.message").html("<i>You were unsubscribed from the publications mailing list.</i>");
    }

    if (type === "webinars") {
      $("form.unsubscribe input#webinars").parent().parent().hide();
      $("form.unsubscribe span.message").html("<i>You were unsubscribed from the webinars mailing list.</i>");
    }

    if (type === "blog") {
      $("form.unsubscribe input#blog").parent().parent().hide();
      $("form.unsubscribe span.message").html("<i>You were unsubscribed from the blog mailing list.</i>");
    }

    $("form.unsubscribe").validate({
      rules: {
        sub: {
          required: function (element) {
            var boxes = $('.checkbox-group');
            if (boxes.filter(':checked').length == 0) {
              return true;
            }
            return false;
          },
          minlength: 1
        }
      },
      messages: {
        sub: "Please select at least one type of subscription."
      },
      submitHandler: function(form,event) {
        var $form = $(form);
        $form.find("button").addClass("icon-spin").prop("disabled", true);
        $.ajax({
          url: "/content/includes/unsubscribe.ashx",
          type: 'POST',
          data: $form.serialize(),
          success: function(msg) {
            $form.html(msg);

            if (typeof dataLayer === "object") {
              dataLayer.push({
                "event": "form-submission",
                "status": "submitted",
                "form_name": "unsubscribe"
              });
            }
          }
        });
      }
    });
  }

  // $("form.blog-subscribe").validate({
  //   rules: {
  //     sub: {
  //       required: function (element) {
  //         var boxes = $('.checkbox-group');
  //         if (boxes.filter(':checked').length == 0) {
  //           return true;
  //         }
  //         return false;
  //       },
  //       minlength: 1
  //     }
  //   },
  //   messages: {
  //     sub: "Please select at least one type of subscription."
  //   },
  //   submitHandler: function(form,event) {
  //     var $form = $(form);
  //     $.ajax({
  //       url: "/content/includes/subscribe.ashx",
  //       type: 'POST',
  //       data: $form.serialize(),
  //       success: function(msg) {
  //         $form.html(msg);

  //         if (typeof dataLayer === "object") {
  //           dataLayer.push({
  //             "event": "form-submission",
  //             "status": "submitted",
  //             "form_name": "blog subscribe"
  //           });
  //         }
  //       }
  //     });
  //   }
  // });

});
